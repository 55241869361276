import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Prospecto } from '@app/_models/prospecto';
import { ResponseRest } from '@app/_models/responseRest';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { Grado } from '@app/_models/grado';

@Injectable({
  providedIn: 'root'
})
export class ProspectoService {

  constructor(private http: HttpClient) { }

  crearProspecto(prospecto: Prospecto): Promise<ResponseRest<string>> {
    return this.http.post<ResponseRest<string>>(`${environment.apiUrl}/Cuenta`, prospecto).toPromise();

  }

  existeUsuario(correo: string):  Promise<ResponseRest<string>> {
    return this.http.post<ResponseRest<string>>(`${environment.apiUrl}/Cuenta/${correo}/Existe`,{}).toPromise(); 
  }

  consultarNiveles(CampusID : string):Promise<Grado[]>{
    return this.http.post<Grado[]>(`${environment.apiUrl}/InteresAcademico/${CampusID}/Niveles`, {}).toPromise();
}

}
