export class Usuario {
  UsuarioID: number;
  ProspectoID: number;
  Id: string;
  Correo: string;
  Nombre: string;
  ApellidoPaterno: string;
  ApellidoMaterno: string;  
  Roles?: string[];
  Campus?: string[];
  IsActive?: boolean;
  IsAdmin?: boolean;
  IsEmpleado?: boolean;
  IsProspecto?: boolean;
  Token?: string;
  Expiracion?: number;
}
