import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { OperationResult } from '@app/_models/operationResult';
import { Pago } from '@app/_models/pago';

@Injectable({
  providedIn: 'root'
})
export class ConciliacionService {

  constructor(private http: HttpClient) { }

  importar(archivo: FormData, banco: string, idProspecto: number) {

    let params = new HttpParams();
    params = params.append('banco', banco );
    params = params.append('idProspecto', idProspecto.toString() );

    const options = {
      headers: new HttpHeaders(),
      params: params
    }

    return this.http.post<OperationResult<Pago[]>>(`${environment.apiUrl}/conciliacion`, archivo, { params } );
  }

  get(nRegistros:number, nPagina:number, filtro: string, tipoFecha: number, desde: Date, hasta: Date){
    let params = new HttpParams();
    params = params.append('numRegistros', nRegistros.toString() );
    params = params.append('pagina', nPagina.toString() );
    params = params.append('filtro', filtro );
    params = params.append('tipoFecha', tipoFecha.toString() );
    params = params.append('desde', desde.toDateString() );
    params = params.append('hasta', hasta.toDateString() );

    return this.http.get<OperationResult<Pago[]>>(`${environment.apiUrl}/conciliacion`, { params });
  }

  getCount(filtro: string, tipoFecha: number, desde: Date, hasta: Date){
    let params = new HttpParams();
    params = params.append('filtro', filtro );
    params = params.append('tipoFecha', tipoFecha.toString() );
    params = params.append('desde', desde.toDateString() );
    params = params.append('hasta', hasta.toDateString() );

    return this.http.get<number>(`${environment.apiUrl}/conciliacion/Count`, { params });
  }


}
