<mat-card class="realiza-pago">  
  <mat-card-content>
    <mat-card-title class="card-title">Módulo de pago</mat-card-title>
        <label>A continuación se muestra el saldo que tienes pendiente por cubrir, selecciona la cantidad a pagar y el método de pago.</label>
         
        <table class="full-width" cellspacing="0">
      <tr>
          <td>
            <br>  
            <br> 
              <label class="sub-title">Saldo</label>
              <br> 
              <br> 
              <div class="col center">
                <mat-card class="card-table">
                  <mat-card-content >
             
                <table class="tablePago" >

                  <br>
                  <tr>
                      <td><label>Universidad</label></td>
                      <br/>
                      <td><label>{{universidad}}</label></td>
                  </tr>
                  <tr>
                      <td><label>Campus</label></td>
                      <br/>
                      <td><label>{{campus}}</label></td>
                  </tr>
                  <br/>
                  <tr>
                      <td><label>Grado</label></td>
                      <br/>
                      <td><label>{{grado}}</label></td>
                  </tr>
                  <tr>
                      <td><label>Periodo</label></td>
                      <br/>
                      <td><label>{{periodo}}</label></td>
                  </tr>
                  <br/>
                  <tr>
                      <td><label>Monto a pagar</label></td>
                      <br/>
                      <td><label>${{ prospecto.Saldo | number : '1.2-2'}}</label></td>
                  </tr>
                  <br/>
                </table>
            </mat-card-content>
          </mat-card>
        </div>
          </td>
      </tr>
      <tr *ngIf="pagosRealizados == true">
          <td>
            <br>  
            <br> 
              <div class="col center">
                <mat-card class="card-table">
                  <mat-card-content >
                      <p *ngIf="prospecto.Saldo > 0"><b>Gracias por tu pago inicial.</b> Te recordamos que pagos subsecuentes los debes realizar través de la aplicación móvil Mi Red Aliat.</p>
                      <p *ngIf="prospecto.Saldo <= 0"><b>Gracias por tu pago.</b> Por favor, continua con el proceso que te explicó tu asesor.</p>
                  </mat-card-content>
                </mat-card>
              </div>
          </td>
      </tr>
      <tr>
        <td>
          <br> 
          <br> 
          <label class="sub-title" *ngIf="prospecto.Saldo > 0 && pagosRealizados == false">Realizar pago</label>
          <br> 
          <br> 
          <div class="col center" *ngIf="prospecto.Saldo > 0 && pagosRealizados == false">
            <mat-card class="card-table">
              <mat-card-content >
                <label class="sub-title">Cantidad a pagar</label>
                <br>
                <br/>
                <br/>
                <mat-radio-group [(ngModel)]="cantidadTipo">
                  <mat-radio-button value="p">
                    <label>${{ prospecto.Saldo | number : '1.2-2'}}</label>
                  </mat-radio-button>
                  <br/>
                  <br/>
                  <mat-radio-button #otra value="o">
                    <label>Otra cantidad</label>
                  </mat-radio-button>
                    <input type="number" class="otra-cantidad" disabled [disabled]="!otra.checked" placeholder="Cantidad" [(ngModel)]="cantidad" value="" min="0" [max]="prospecto.Saldo" />
                </mat-radio-group>
                <div *ngIf="cantidadTipo == 'o'" class="text-center">
                  <span class="alerta alerta-success full-width">
                    Al realizar un pago de manera parcial, los pagos subsecuentes serán a través de la aplicación móvil Mi Red Aliat.
                  </span>
                </div>
                <br>
                <label class="sub-title">Método de pago </label>
                <br>
                <br/>
                <br/>
                <mat-radio-group [(ngModel)]="metodoPago">
                  <mat-radio-button value="p">
                    <label>Tarjeta crédito/débito</label>
                  </mat-radio-button>
                  <br/>
                  <br/>
                  <mat-radio-button value="o"> 
                    <label>Referencia de pago</label>
                  </mat-radio-button>
                </mat-radio-group>
                <div *ngIf="!!error" class="text-center">
                  <span class="alerta alerta-warning full-width">
                    {{ error }}
                  </span>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </td>
      </tr>
    </table>
    <br/>
    <div class="text-center">
      <button class="color-btn" mat-raised-button (click)="pagar()" *ngIf="prospecto.Saldo > 0 && pagosRealizados == false">Pagar</button>
    </div>
  </mat-card-content>
</mat-card>
