<mat-action-list dense>
  <mat-list-item [fxShow]="isAuthenticated">
    <mat-icon mat-list-icon aria-hidden="false" aria-labelledby="usuario-perfil-label">person</mat-icon>
    <h3 matLine id="usuario-perfil-label" class="sidebar-username"> {{userName}} </h3>
    <span matLine> {{isAdmin ? "Administrador" : isColab ? "Colaborador" : "Prospecto" }} </span>
  </mat-list-item>
  <mat-divider [fxShow]="isAuthenticated"></mat-divider>
  <h3 matSubheader [fxShow]="isAdmin">Administración</h3>
  <button [fxShow]="isAdmin" mat-list-item routerLink="/administracion-usuarios">
    <mat-icon mat-list-icon aria-hidden="false" aria-labelledby="admin-user-label">group</mat-icon>
    <span id="admin-user-label">Administrar Usuarios</span>
  </button>
  <mat-divider [fxShow]="isAdmin"></mat-divider>
  <h3 matSubheader [fxShow]="isColab">Colaborador</h3>
  <button [fxShow]="isAmonProspecto" mat-list-item routerLink="/administracion-prospectos">
    <mat-icon mat-list-icon aria-hidden="false" aria-labelledby="admin-pro-label">contacts</mat-icon>
    <span id="admin-pro-label">Administrar Prospectos</span>
  </button>
  <button [fxShow]="isConciliacion" mat-list-item routerLink="/conciliacion-bancaria">
    <mat-icon mat-list-icon aria-hidden="false" aria-labelledby="conc-banc-label">account_balance</mat-icon>
    <span id="conc-banc-label">Conciliación Bancaría</span>
  </button>
  <button [fxShow]="isReportes" mat-list-item routerLink="/reporte">
    <mat-icon mat-list-icon aria-hidden="false" aria-labelledby="reporte-label">assessment</mat-icon>
    <span id="reporte-label">Reportes</span>
  </button>
  <mat-divider [fxShow]="isColab"></mat-divider>
  <h3 matSubheader [fxShow]="isProspect">Prospecto</h3>
  <button [fxShow]="isProspect" mat-list-item routerLink="/consulta-pagos">
    <mat-icon mat-list-icon aria-hidden="false" aria-labelledby="pagos-label">payment</mat-icon>
    <span id="pagos-label">Pagos</span>
  </button>
  <button [fxShow]="isProspect" mat-list-item routerLink="/estado-cuenta">
    <mat-icon mat-list-icon aria-hidden="false" aria-labelledby="estado-cuenta-label">attach_money</mat-icon>
    <span id="estado-cuenta-label">Estado de Cuenta</span>
  </button>
  <mat-divider [fxShow]="isProspect"></mat-divider>
  <button mat-list-item routerLink="/ayuda">
    <mat-icon mat-list-icon aria-hidden="false" aria-labelledby="ayuda-label">help</mat-icon>
    <span id="ayuda-label">Ayuda</span>
  </button>
  <button mat-list-item (click)="logout()">
    <i mat-list-icon aria-hidden="false" aria-labelledby="cerrar-label" class="fas fa-sign-out-alt"></i>
    <span id="cerrar-label">Cerrar Sesión</span>
  </button>
</mat-action-list>
