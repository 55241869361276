import { TipoFecha } from './tipoFecha';
import { Campus } from './campus';
import { Grado } from './grado';

export class Reporte {


  selectedFechas :number[];
  selectedGrados : number [];
  selectedInstituciones : string [];  
  selectedCampus : string [];
  FechaInicial : Date;
  FechaFinal : Date;
  formato : number;
  idUsuario: number;

  }
  
