import { Component, OnInit } from '@angular/core';
import {FormGroup } from '@angular/forms';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DetalleUsuarioComponent } from '../_components/detalle-usuario/detalle-usuario.component';
import { PagoService } from '../_services/pago.service';
import { Prospecto } from '../_models/prospecto';

@Component({
  selector: 'app-estado-cuenta',
  templateUrl: './estado-cuenta.component.html',
  styleUrls: ['./estado-cuenta.component.scss']
})

export class EstadoCuentaComponent implements OnInit {


  displayedColumns: string[] = [ 'area', 'nombre', 'correo', 'cargo','abono', 'metodo'];
  public dataSource;

  public prospecto: Prospecto;
  public cargos: number;
  public pagos: number;
  public ELEMENT_DATA: MovimientoRenglon[];

  constructor(
    private pagoService: PagoService
  ) {
    this.prospecto = new Prospecto;
  }

  ngOnInit() {
    this.pagoService.obtieneProspecto()
    .subscribe(
      data => {
        this.prospecto = data;
        this.cargos = 0;
        this.pagos = 0;
        let movimientos: MovimientoRenglon[] = [];
        this.prospecto.Cargos.forEach(c => {
          this.cargos += c.Monto;

          movimientos.push({
            fechaOp: c.FechaCreacion,
            fechaApl: c.FechaCreacion,
            concepto: "Inscripción",
            cargo : c.Monto,
            abono : null,
            metodoPago : ""
          });
        });
        this.prospecto.Pagos.forEach(c => {
          if (c.FechaAplicacion != null) {

            this.pagos += (c.FechaAplicacion != null ? c.Monto : 0);
            movimientos.push({
              fechaOp: c.FechaAplicacion,
              fechaApl: c.FechaCreacion,
              concepto: "Pago ",
              cargo : null,
              abono : c.Monto,
              metodoPago : c.FormaPago
            });

          }
        });
        
        movimientos = movimientos.sort((a, b) => b.fechaOp.valueOf() - a.fechaOp.valueOf());
        this.ELEMENT_DATA = movimientos;

        this.dataSource = new MatTableDataSource<MovimientoRenglon>(this.ELEMENT_DATA);
      });
  }

}

export interface MovimientoRenglon {
  fechaOp: Date;
  fechaApl?: Date;
  concepto: string;
  cargo: number;
  abono: number;
  metodoPago: string;
}
