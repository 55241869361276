<mat-card class="admin-user-card">
  <mat-card-title class="card-title">Asignación de información de pagos a prospectos</mat-card-title>

<form class="example-form" name="adminProspectosForm" >       
  
  <button mat-raised-button  matTooltip="Agregar nuevo prospecto" class="color-btn" (click)="agregarProspecto()" >+ Agregar</button>              
  <br>
  <br>

  <mat-form-field>
    <mat-label>Filtro de búsqueda:</mat-label>
    <input matInput  [(ngModel)]="filtro"  (input)="filterText()" id="filter" name="filter">
  </mat-form-field>

</form>

<div class="example-container mat-elevation-z8">

  <mat-table [dataSource]="dataSource" matSort>


    <ng-container matColumnDef="correo">
      <mat-header-cell *matHeaderCellDef > Correo </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="correo"> {{element.Correo}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="campus">
      <mat-header-cell *matHeaderCellDef > Campus </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="campus">{{element.Campus.CampusID}}  {{element.Campus.Nombre}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="monto">

        <mat-header-cell *matHeaderCellDef > Monto </mat-header-cell>
        <mat-cell *matCellDef="let element"  data-label="monto">
              {{element.Monto | currency:"MXN"}}  
          </mat-cell>
      </ng-container>

        <ng-container matColumnDef="inter">
          <mat-header-cell *matHeaderCellDef >  </mat-header-cell>
          <mat-cell *matCellDef="let element"  data-label="inter">  </mat-cell>
        </ng-container>      

      <ng-container matColumnDef="opciones">
        <mat-header-cell *matHeaderCellDef >
         Opciones 
        </mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="opciones">
            <button mat-icon-button color="basic"  (click)="editarProspecto(element)"  [disabled]="!element.Editable"
                matTooltip="Editar los datos del cargo"  aria-label="Editar"><mat-icon>edit</mat-icon></button>
            <!--<button mat-icon-button color="accent" (click)="eliminarCargo(element)"
              [disabled]="!element.Editable"
                matTooltip="Eliminar el cargo"   aria-label="Eliminar"><mat-icon>delete_forever</mat-icon></button> -->                   
            <!--button mat-icon-button color="basic"
                matTooltip="Historial del prospecto"  aria-label="Mas información"><mat-icon>history</mat-icon></button-->          
        </mat-cell>
      </ng-container>


      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table> 
    <mat-paginator 
      [length]="length"
      [pageIndex]="pageIndex"
      [pageSize]="pageSize"
      [pageSizeOptions]="[5, 10, 20]" 
      (page)="pageEvent = getServerData($event)" >
    </mat-paginator>       


</div>
 
</mat-card>
  
  