import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Campus } from '@app/_models/campus';

@Injectable({ providedIn: 'root' })
export class CampusService {

    constructor(private http: HttpClient) {
      }


consultaCampus(idUsuario: number):Promise<Campus[]>{
    return this.http.get<Campus[]>(`${environment.apiUrl}/campus?idUsuario=${idUsuario}`).toPromise();

}


consultaCampusXInstituciones(ids : string[], idUsuario: number):Promise<Campus[]>{

    return this.http.post<Campus[]>(`${environment.apiUrl}/campus?idUsuario=${idUsuario}`, ids).toPromise();

}

consultaInstituciones(idUsuario: number):Promise<string[]>{
    return this.http.get<string[]>(`${environment.apiUrl}/Institucion?idUsuario=${idUsuario}`,).toPromise();

}


getAll():Promise<Campus[]>{
    return this.http.get<Campus[]>(`${environment.apiUrl}/Campus/GetAll`).toPromise();

}
 
}
