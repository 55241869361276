<mat-card class="recupera-card">
    <br>
    <mat-card-title class="card-title"><div class="titulo-danger">¡Enlace inválido!</div></mat-card-title>
    <br>
    <div class="texto-informativo">
        <label class=''>
        El enlace de recuperación de contraseña ya no es válido. 
        <br>
        <br>
        Si requieres recuperar tu contraseña, de favor ingresa <a id="link" href="#" routerLink="/recupera-cuenta" >aquí</a>.
        <br>
        </label>           
    </div>
</mat-card>
    