import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, ValidatorFn, AbstractControl } from '@angular/forms';
import { ThemePalette, ErrorStateMatcher } from '@angular/material/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ProspectoService } from '@app/_services/prospecto.service';
import { Prospecto } from '@app/_models/prospecto';
import { Usuario } from '@app/_models/usuario';
import { Genero } from '@app/_models/genero';
import { Router } from '@angular/router';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { environment } from '@environments/environment';
										   

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-MX' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],

})




export class RegistroComponent implements OnInit {
  cambioPasswordForm: FormGroup;
  confirmarControl: FormControl;

  colorForce: ThemePalette = 'primary';
  valueForce = 0;
  bufferValue = 75;

  password = "";
  containsPssword = false;

  date = new FormControl(null);

  minDate: Date;
  maxDate: Date;
  //private captcha: string;

  passMatch = true;

  matcher = new PasswordErrorStateMatcher();
  private existeUsuario;
  dateError = false;
  enableBtn = false;
  dateNac= new Date();
  correo : string;

  public SITE_KEY: string = '';
  TokenReCaptcha: string = '';

  correoError = false;
  fortalezaError = false;
  mostrarDescripcionPass = false;

  @ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;
  //constructor() { }
  constructor(public dialog: MatDialog, private formBuilder: FormBuilder, private prospectoService: ProspectoService, private router: Router, ) {
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 100, 0, 1);
    this.maxDate = new Date(currentYear - 10, 0, 1);
    this.date = new FormControl(null);
    this.confirmarControl = new FormControl('',[]);
  }

  ngOnInit() {
    this.SITE_KEY = environment.SITE_KEY_Captcha;    
    this.addRecaptchaScript();

    var that = this;

    this.existeUsuario = function(form: FormControl){

      console.log("Validando si esxiste el usuario");
      if (!form || !form.value) {
        return null;
      }
      let correo = form.value;
      console.log("Correo " + correo);
      that.prospectoService.existeUsuario(correo).then(res => {
        if(res.Dato){
           console.log("Correo " + res.Dato);
            return  { existe: true }
        }else{
          console.log("No Correo " + res.Dato);
          return  { existe: false }
        }
      })
      .catch(err => {
        const message = `¿Ocurrio un error al conectar con el servidor?`;
        return  { existe: false }
      });
    };

    this.cambioPasswordForm = this.formBuilder.group({
      correo: ['', Validators.required],
      nombre: ['', Validators.required],
      primerApellido: ['', Validators.required],
      segundoApellido: [''],
      fechaNacimiento: ['', Validators.required],
      genero: ['', Validators.required],
      password: new FormControl('', [Validators.required]),
      confirmPassword: this.confirmarControl,
      acepto: ['', Validators.required],

    }, { validators: this.checkPasswords });
  }


  
  get f() { return this.cambioPasswordForm.controls; }

  renderReCaptch() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey': this.SITE_KEY ,
      'callback': (response) => {
        this.TokenReCaptcha = response;
      }
    });
  }



  addRecaptchaScript() {

    window['grecaptchaCallback'] = () => {
      this.renderReCaptch();
    }

    (function (d, s, id, obj) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderReCaptch(); return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));

  }

  cambiarContrasena() {

    this.updateProgressBar();

    if (this.cambioPasswordForm.invalid) {
      return;
    }

  }

  updateProgressBar() {
    this.fortalezaError = false;
    this.containsPssword = false;
    if (this.f.password.value == undefined) return;
    if (this.f.password.value == null) return;
    if (this.f.password.value.trim() == '') return;

    this.valueForce = this.checkStrength(this.f.password.value);

    if (this.valueForce <= 45) {
      this.fortalezaError = true;
      this.colorForce = 'warn';
    } else if (this.valueForce <= 75) {
      this.colorForce = 'primary';
    } else {
      this.colorForce = 'primary';
    }

    this.containsPssword = true;
  }

  checkStrength(p) {
    // 1
    let force = 0;

    // 2
    const regex = /[$-/:-?{-~!"^_@`\[\]]/g;
    const lowerLetters = /[a-z]+/.test(p);
    const upperLetters = /[A-Z]+/.test(p);
    const numbers = /[0-9]+/.test(p);
    const symbols = regex.test(p);

    // 3
    const flags = [lowerLetters, upperLetters, numbers, symbols];

    // 4
    let passedMatches = 0;
    for (const flag of flags) {
      passedMatches += flag === true ? 1 : 0;
    }

    // 5
    force += 2 * p.length + ((p.length >= 10) ? 1 : 0);
    force += passedMatches * 10;

    // 6
    force = (p.length <= 6) ? Math.min(force, 10) : force;

    // 7
    force = (passedMatches === 1) ? Math.min(force, 10) : force;
    force = (passedMatches === 2) ? Math.min(force, 20) : force;
    force = (passedMatches === 3) ? Math.min(force, 30) : force;
    force = (passedMatches === 4) ? Math.min(force, 40) : force;

    if (force == 20) return 45;
    if (force == 30) return 75;
    if (force == 40) return 100;
    return force;
  }

  crearCuenta() {


    if(!this.cambioPasswordForm.valid){
      this.showMessage('Debe llenar todos los campos marcados con *', 'Error');
      return;

    }
    
    if(this.correoError){
      this.showMessage('Debe ingresar un correo válido', 'Error');
      return;

    }    
                  
    var responseCaptcha = grecaptcha.getResponse();
    if (responseCaptcha.trim() == '') {     
      const dialogData = new ConfirmDialogModel('Validación', 'Por favor seleccione la casilla del captcha.', false);    
      const dialogRef = this.dialog.open(AlertaComponent, {
        maxWidth: "400px",
        data: dialogData
      });
      return;
    }        

    if (this.valueForce <75) {
      this.showMessage('La contraseña no cumple con los requisitos de seguridad', 'Error');
      return;
    }    

    if (this.cambioPasswordForm.get('password').value != this.cambioPasswordForm.get('confirmPassword').value) {
      this.showMessage('No coinciden los Passwords', 'Error');
      return;
    }


    if (!this.cambioPasswordForm.valid) {
      return;
    }

    if (!this.cambioPasswordForm.get('acepto').value) {
      return;
    }

    var prospecto = new Prospecto()

    prospecto.Password = this.cambioPasswordForm.get('password').value;
    prospecto.FechaNacimiento = this.cambioPasswordForm.get('fechaNacimiento').value;

    prospecto.Usuario = new Usuario();
    prospecto.Usuario.Correo = this.cambioPasswordForm.get('correo').value;
    prospecto.Usuario.Nombre = this.cambioPasswordForm.get('nombre').value;
    prospecto.Usuario.ApellidoPaterno = this.cambioPasswordForm.get('primerApellido').value;
    prospecto.Usuario.ApellidoMaterno = this.cambioPasswordForm.get('segundoApellido').value;

    prospecto.Genero = new Genero();
    prospecto.Genero.GeneroID = this.cambioPasswordForm.get('genero').value;

    prospecto.Captcha = this.TokenReCaptcha;
    this.correo = prospecto.Usuario.Correo;
														

    this.prospectoService.crearProspecto(prospecto).then(res => {
      if (res.Exito) {
        this.storePassword(prospecto.Usuario.Correo,prospecto.Password);
        this.router.navigate(['/registro-espera', this.correo]);
      } else {
        //this.error = true;
        const dialogData = new ConfirmDialogModel('Error', res.Mensaje, false);    
        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        });

      }
    })
      .catch(err => {
        const message = `¿Ocurrio un error al conectar con el servidor?`;

        const dialogData = new ConfirmDialogModel("Error", message, false);

        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        });

        dialogRef.afterClosed().subscribe(dialogResult => { });
      })
      grecaptcha.reset();
  }

  private showMessage(message: string, titulo: string): MatDialogRef<AlertaComponent, any> {
    //const message = `¿Ocurrio un error al conectar con el servidor?`;

    const dialogData = new ConfirmDialogModel(titulo, message, false);

    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    return dialogRef
  }

  checkPasswords(group: FormGroup) {
    if (!group || !group.get('password') || !group.get('confirmPassword')) {
      return null;
    }

    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value;
																																					  

    return ((!confirmPass) || (pass === confirmPass)) ? null : { notSame: true }
  }


  validarCorreo() {
    this.correoError=false;
    var correo =  this.cambioPasswordForm.get('correo').value;
    
    if(correo != null && correo.length > 0 ){
      var mailformat =`(?:[a-z0-9!#$%&'*+/=?^_\`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_\`{|}~-]+)*|"`
      + `(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")`
      + `@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?`
      + `|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]`
      + `:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])`;
      var match = correo.match(mailformat);
        if ( !match)
        {
          this.correoError=true;
        }
    }
  }



  cadenisa(value) {
    return JSON.stringify(value)
  }

  validar() {
    this.dateError=false;

    if (this.cambioPasswordForm.get('fechaNacimiento').value == null){
      this.dateError=true;
    }

    if (this.cambioPasswordForm.invalid) {
      this.enableBtn = false;
    } else {
      this.enableBtn = true;
    }     
  }

  mostrarDescripcion() {
    this.mostrarDescripcionPass= true;
  }
  
  
  ocultarDescripcion() {
    this.mostrarDescripcionPass= false;
  }


  private async storePassword(username: string, password: string): Promise<any> {
    if (!(window as any).PasswordCredential) {
      return Promise.resolve();
    }

    const cred = new (window as any).PasswordCredential({
      id: username,
      password,
      name: username
    });
    return (navigator as any).credentials.store(cred);
  }  

}



export class PasswordErrorStateMatcher implements ErrorStateMatcher {

															   
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || control.parent.hasError('notSame'));
  }
  
}




