import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { AuthenticationService } from '@app/_services/authentication.service'


@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
  }

  ngOnInit() {
    if (!!this.authenticationService.currentUserValue) {
      if (this.authenticationService.currentUserValue.Roles.indexOf("ADMIN") > -1) {
        this.router.navigate(['/administracion-usuarios']);
        return;
      }
      if (this.authenticationService.currentUserValue.Roles.indexOf("CARGOS") > -1) {
        this.router.navigate(['/administracion-prospectos']);
        return;
      }
      if (this.authenticationService.currentUserValue.Roles.indexOf("CONCILIA") > -1) {
        this.router.navigate(['/conciliacion-bancaria']);
        return;
      }
      this.router.navigate(['/consulta-pagos']);
    } else {
      this.authenticationService.logout();
      this.router.navigate(['/login']);
    }
  }

}
