import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Ciclo } from '@app/_models/ciclo';

@Injectable({ providedIn: 'root' })
export class CicloService {

    constructor(private http: HttpClient) {
      }


consultaCiclo():Promise<Ciclo[]>{
    return this.http.get<Ciclo[]>(`${environment.apiUrl}/ciclo`).toPromise();

}



 
}
