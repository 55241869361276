<mat-card class="registro-card">
  <mat-card-title class="card-title">Reporte de pagos </mat-card-title>
<div class="principal1">
  <div class="principal2">

      <form class="page-form" name="reporteForm" id="reporteForm" [formGroup]="reporteForm">  
          <br/>


          <div class="page-width-80">
            <mat-form-field class="page-full-width">
              <label>Tipo de fecha</label>
              <mat-select  #mySelFecha formControlName="tipoFecha" [(value)]="selectedFechas" multiple>
                <mat-option #allSelectedFecha (click)="toggleAllSelectionFechas()" [value]="0">Todos</mat-option>
                <!--mat-option #allSelected  [value]="'Todos'" (click)="toggleAllSelection()"><label>Todos</label></mat-option-->
                <mat-option *ngFor="let fecha of fechasList" [value]="fecha.TipoID" 
                (click)="tosslePerOneFechas(allSelectedFecha.viewValue)">
                  {{fecha.Nombre}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>  

          <div >
            <label>Rango de fechas:</label>
          </div>  

          <div class="page-width-80">
            <mat-form-field class="page-half-width">
              <label>De:</label>
              <input formControlName="fechaInicio" matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker1">              
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
            <span class="space"> - </span>
            <mat-form-field >
              <label>A:</label>
              <input formControlName="fechaFin" matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker2">              
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>          
          </div>
  


          <div class="page-width-80">
            <mat-form-field class="page-full-width">
              <label>Instituciones</label>
              <mat-select  #mySelInstitucion formControlName="institucion" 
              (openedChange)="onSelectInstituciones($event)"
                  [(value)]="selectedInstituciones" multiple>
                <mat-option #allSelectedInstitucion (click)="toggleAllSelectionInstituciones()" [value]="0">TODAS</mat-option>
                <mat-option *ngFor="let inst of instituciones" [value]="inst" 
                (click)="tosslePerOneInstituciones(allSelectedInstitucion.viewValue)">
                  {{inst}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>  

          <div>
            
            <mat-form-field class="page-width-80">
              <label>Campus</label>
              <mat-select #mySelCampus  formControlName="campus" [(value)]="selectedCampus"  multiple>
                <mat-option  #allSelectedCampus  (click)="toggleAllSelectionCampus()" [value]="'0'"><label>Todos</label></mat-option>
                <mat-option *ngFor="let c of campus" [value]="c.CampusID"
                (click)="tosslePerOneCampus(allSelectedCampus.viewValue)">
                  {{c.InstitucionID}}-{{c.Nombre}}
                </mat-option>
              </mat-select>
            </mat-form-field>            


          </div>
        
          <div>
            <mat-form-field class="page-width-80">
              <label>Grados</label>
              <mat-select   #mySelGrado  formControlName="grado"   [(value)]="selecetedGrados" multiple>
                <mat-option #allSelectedGrado (click)="toggleAllSelectionGrado()"  [value]="0"><label>Todos</label></mat-option>
                <mat-option *ngFor="let c of grados" [value]="c.GradoID"
                    (click)="tosslePerOneGrado(allSelectedGrado.viewValue)">
                  {{c.Nombre}}
                </mat-option>                
              </mat-select>
            </mat-form-field>
          </div>  
          <br>                  

          <br>

          <!--div>
            <mat-radio-group aria-label="Seleccione una opcion" class="radio" formControlName="formato"> 
              <mat-radio-button  value="1"><label>Excel</label></mat-radio-button>
              <span class="space"> </span>
              <mat-radio-button value="2"><label>PDF</label></mat-radio-button>
            </mat-radio-group>
          </div-->

        <br/>
  
        <div  class="text-center">
          <button  mat-raised-button color="primary"  (click)="generar()" [disabled]="!reporteForm.valid"  ><mat-icon>cloud_download</mat-icon> Descargar</button>

        </div>

        <br/>
        <br/>

          
        </form>
        
  </div>


</div>
</mat-card>

