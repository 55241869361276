<mat-card class="registro-card">
  <mat-card-title class="card-title">Crear cuenta</mat-card-title>
  <br>
  <div class="principal1">
      <div class="principal2">
          <form class="registro-form" name="cambioPasswordForm" id="cambioPasswordForm" [formGroup]="cambioPasswordForm">  
                <div class="registro-full-width">
                  <label>Crear una cuenta favor de agregar el correo que te dió tu asesor</label>
                </div>
                <br>
                <mat-form-field class="registro-full-width">
                  <mat-label>Correo electrónico</mat-label>
                  <input matInput placeholder="correo@example.com"  (ngModelChange)="validarCorreo()"
                   matTooltip="Ingrese un correo electrónico, será tu nombre de usuario" 
                   value="" formControlName="correo"  >
                  <mat-error *ngIf="cambioPasswordForm.hasError('existe')">
                    El correo ya se  dió de alta en el sistema
                 </mat-error>  
                 <div *ngIf="correoError">
                  <mat-error *ngIf="correoError"> Ingrese un correo válido </mat-error>
                </div>                                   
                </mat-form-field>              
            
              <mat-form-field class="registro-full-width">
                <mat-label>Nombre*</mat-label>
                <input matInput placeholder="Ingrese su nombre" value="" formControlName="nombre">
              </mat-form-field>
            
            
              <mat-form-field class="registro-full-width">
                <mat-label>Primer apellido*</mat-label>
                <input matInput placeholder="Ingrese primer apellido" value="" formControlName="primerApellido">
              </mat-form-field>
            
              <mat-form-field class="registro-full-width">
                <mat-label>Segundo apellido</mat-label>
                <input matInput placeholder="Ingrese segundo apellido" value="" formControlName="segundoApellido">
              </mat-form-field>
            
            
              <mat-form-field class="registro-full-width">
                <mat-label>Fecha de nacimiento*</mat-label>
                <input matInput [min]="minDate" [max]="maxDate"  placeholder="dd/mm/yyyy" 
                      (ngModelChange)='validar()'  (input)='validar()'
                      [matDatepicker]="picker" formControlName="fechaNacimiento">    
                      <div *ngIf="dateError">
                        <mat-error *ngIf="dateError"> La fecha ingresada no tiene un formato correcto. </mat-error>
                      </div>                                   
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            
            
              <div class="registro-full-with">
                  <mat-label class="registro-label">Genero*:</mat-label>
                  <br>
                  <mat-radio-group aria-label="Seleccione una opcion"  formControlName="genero">
                    <mat-radio-button value="1"><label>Hombre</label></mat-radio-button>
                    <mat-radio-button value="2"><label>Mujer</label></mat-radio-button>
                  </mat-radio-group>
      
              </div>
              <br>
              <mat-label>Contraseña</mat-label> <br>
     
              <mat-form-field class="registro-full-width">
                <mat-label>Escribe la contraseña</mat-label>
                <input matInput type="password" id="password" name="password" autocomplete="new-password"
                  formControlName="password" required (ngModelChange)="updateProgressBar()" 
                  (focus)="mostrarDescripcion()" (focusout)="ocultarDescripcion()"
                  matTooltip="La contraseña debe tener al menos 8 caracteres, mayúsculas, minúsculas y al menos un número o caracter especial">
                  <div *ngIf="fortalezaError">
                    <mat-error *ngIf="fortalezaError">La contraseña no cumple con los requisitos de seguridad </mat-error>
                  </div>            
              </mat-form-field>
              <div *ngIf="mostrarDescripcionPass">
                <mat-label  class="registro-label-pass">Debe contener al menos 8 caracteres, mayúsculas, minúsculas y al menos un número o caracter especial</mat-label>       
              </div>            
            
              <mat-progress-bar *ngIf="containsPssword"
              mode="determinate"
              class="example-full-width"
              [color]="colorForce"        
              [value]="valueForce"
              [bufferValue]="bufferValue" >
              </mat-progress-bar>
            
            
              <mat-form-field class="registro-full-width">
                  <!-- mat-label>Vuelve a escribir tu contraseña</mat-label -->
                  <input matInput placeholder="Vuelve a escribir tu contraseña" 
                    type="password" autocomplete="new-password2"
                    id="confirmPassword" name="confirmPassword" 
                    formControlName="confirmPassword" [errorStateMatcher]="matcher">
                    <mat-error *ngIf="cambioPasswordForm.hasError('notSame')">
                     El password no coincide
                  </mat-error>  
              </mat-form-field>
                
              <table id="aviso">
                <tr>
                  <td><mat-checkbox formControlName="acepto" required></mat-checkbox> </td>
                  <td>
                    <div>
                      <div> Acepto el <a target="blank" href="https://www.aliatuniversidades.com.mx/aviso-de-privacidad/?utm_source=Internet&utm_medium=Directo">Aviso de privacidad</a>  </div>
                  </div>    
                  </td>
                </tr>
              </table>
                  
              
              
            
            
              <br/>
              <div #recaptcha></div>
              
              <br/>
              
              <div class="text-center">
                <button class="color-btn" mat-raised-button  (click)="crearCuenta()" >Crear cuenta</button>
              </div>

              <br/>
            
            </form>
            
      </div>


  </div>  
    
</mat-card>

