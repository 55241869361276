import { Usuario } from './usuario';
import { Cargo } from './cargo';
import { Pago } from './pago';
import { Genero } from './genero';

export class Prospecto {
  ProspectoID: number;
  Usuario: Usuario;
  Genero: Genero;
  Saldo: number;
  Cargos: Cargo[];
  Pagos: Pago[];
  FechaNacimiento: Date;
  Password: string;

  Captcha: string;



}
