<app-navigation-bar></app-navigation-bar>

<mat-sidenav-container class="sidebar-container">
  <mat-sidenav [mode]="sidebarMode" [position]="sidebarPosition" [(opened)]="sidebarOpened" #sideBar>
    <app-side-bar></app-side-bar>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-loading-screen></app-loading-screen>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>


<app-footer></app-footer>
