import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { AuthenticationService } from '@app/_services/authentication.service'
import { NavigationService } from '@app/_services/navigation.service';
import { environment } from '@environments/environment';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Mi Caja Aliat';

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private navigationService: NavigationService,
  ) {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', environment.gaKey,
          {
            'page_path': event.urlAfterRedirects
          }
        );
      }
    });
    this.navigationService.isMobile.subscribe(data => {
      let modoAnterior = this.sidebarMode;
      this.sidebarMode = data ? "over" : "side";
      this.sidebarPosition = data ? "end" : "start";
      if (!data && !this.sidebarOpened) {
        this.sidebarOpened = true;
        this.navigationService.isToggledMenu.next(this.sidebarOpened);
      }
      if (data && this.sidebarOpened && this.sidebarMode != modoAnterior && this.sidebarMode == "over") {
        this.sidebarOpened = false;
        this.navigationService.isToggledMenu.next(this.sidebarOpened);
      }

      if (!this.authenticationService.isAuthenticated) {
        this.sidebarOpened = false;
        this.navigationService.isToggledMenu.next(this.sidebarOpened);
      }
      return;
    })

    this.navigationService.ToggleMenuValue.subscribe(data => {
        this.sidebarOpened = data;
    });
  };

  public isAuthenticated() {
    return this.authenticationService.isAuthenticated;
  }

  public sidebarMode: string = "side";
  public sidebarPosition: string = "start";
  public sidebarOpened: boolean = true;
}
