

<mat-card class="contenedor login"> 
  <mat-card class="none"  style="background-color:rgba(0,0,0,0.5);">
    <br>
    <br>
    <br>
    <div>
      <img mat-card-image src="/../assets/logo-aliat.png">
    </div>
    <br>
    <br>
    <br>
    <br>
    <div>
      <div class="txt-center none">
        <mat-label>Bienvenid@ al módulo de pagos “Mi Caja Aliat”.</mat-label>
          <br>
          <br>
          <br>
        <mat-label>Por favor regístrate para realizar algún pago <br> a cualquiera de nuestras universidades.</mat-label>
      </div>
    </div>
  </mat-card>
  <mat-card class="none separador">
    
  </mat-card>
  <mat-card-content class="col-xs-12 col-xl-12" style="margin-right: 2rem auto;">
    <br/><br/>
    <div class="txt-center dpNone" >
      <mat-card-title>Bienvenid@ al módulo de pagos “Mi Caja Aliat”.</mat-card-title>
      <br>
    <mat-label>Por favor regístrate para realizar algún pago <br> a cualquiera de nuestras universidades..</mat-label>
    </div>
    <br>
    <br>
    <mat-card-title class="txt-center">Iniciar sesión</mat-card-title>
    <div>
      <form style="text-align: -webkit-center; ">
        <div class="full-width field-container">
        <input type="text" placeholder="Correo" [(ngModel)]="usuario" name="usuario" required>
        <br/>
        <input placeholder="Contraseña" [(ngModel)]="password" type="password" name="password" required>
        </div>
          <button type="button" mat-flat-button color="accent" class="color-btn" (click)="login()">INGRESAR</button>
        <div *ngIf="error" class="alerta alerta-warning text-center full-width" style="margin: 0.5rem 0;">
        {{errorMessage}}
        </div>
      </form>
      <br/>
    </div>  
  <div class="txt-center">
    <label>¿No tienes cuenta?</label>
  </div>
  <div class="txt-center">
    <a href="#" routerLink="/registro">Regístrate Aquí</a>
  </div> 
  <div class="txt-center">
    <label>¿Nunca has entrado al portal de pagos?</label>
  </div>
  <div class="txt-center">
      <a id="link" href="#" routerLink="/recupera-cuenta" >Recupera tu cuenta aquí</a>
  </div>
</mat-card-content>

</mat-card>
