<mat-card class="registro-card">
    <div class="center">
      <mat-card class="none">
          <mat-card-title class="card-title"><div class="el-titulo">Pago Procesado</div></mat-card-title>
          <br>
          <div class="texto" style='padding-bottom: 150px;'>
           El pago se encuentra procesado. En caso de que el banco lo haya aprobado, se verá reflejado en <a href="#" routerLink="/estado-cuenta">tu estado de cuenta</a> a más tardar en las próximas 24 horas.
         </div>
          <div>REF: {{referencia}}</div>

      </mat-card>
    </div>
  </mat-card>
