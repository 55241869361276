import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '@environments/environment';
import { LoadingScreenService } from '@app/_services/loading-screen.service';

@Component({
  selector: 'app-pago-procesado',
  templateUrl: './pago-procesado.component.html',
  styleUrls: ['./pago-procesado.component.scss']
})
export class PagoProcesadoComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingScreenService
  ) { }

  public referencia: string;

  ngOnInit(): void {
    this.loadingService.startLoading();
    this.referencia = this.route.snapshot.queryParamMap.get('referencia');

    if (!this.referencia.startsWith("PPA0")) {
      let parametros: string[] = [];
      this.route.snapshot.queryParamMap.keys.forEach((llave: string) => {
        parametros.push(`${llave}=${this.route.snapshot.queryParamMap.get(llave)}`);
      });
      location.href = `${environment.admisionesUrl}?${parametros.join("&")}`;
      return;
    }

    this.loadingService.stopLoading();

  }

}
