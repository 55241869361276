<mat-card class="registro-card">
    <div class="center">
      <mat-card class="none">
          <mat-card-title class="card-title"><div class="el-titulo">¡Tu cuenta ha sido creada!</div></mat-card-title>
          <br>
          <div class="texto" style='padding-bottom: 150px;'>
           Se ha registrado la cuenta: {{correo}}.
            <br>
            <br>
            En los próximos 10 minutos recibirás un correo electrónico de  noreply5@redaliat.mx con los pasos para activar tu contraseña.
            <br>
            <br>
            En caso de que no lo recibas, te pedimos que revises la bandeja de correos no deseados o SPAM.
         </div>

      </mat-card>
    </div>
  </mat-card>
  