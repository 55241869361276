import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-registro-espera',
  templateUrl: './registro-espera.component.html',
  styleUrls: ['./registro-espera.component.scss']
})
export class RegistroEsperaComponent implements OnInit {

  correo = '';

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.correo = this.route.snapshot.paramMap.get('correo');

  }

}
