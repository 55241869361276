import { Campus } from './campus';
import { Ciclo } from './ciclo';
import { Grado } from './grado';

export class Cargo {

    CargoID : number;
    Correo : string;
    Monto : number;
    NumeroOportunidad : string;
    Campus: Campus;
    Grado : Grado;
    Ciclo: Ciclo;
    FechaCreacion: Date;
    Editable :boolean;
    UsuarioCreacion : number

  
}
