 
<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
    <mat-icon class="icon-left">menu</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item>
      <mat-icon>person</mat-icon>
      <span>Paola Gonzales</span>
    </button>
    <button mat-menu-item>
        <span>paola.gzz@gmail.com</span>
      </button>
    <br/>
    <mat-divider></mat-divider>
    <br/>
    <button mat-menu-item>
      <span><b>Pagos</b></span>
    </button>
    <button mat-menu-item>
      <span>Estado de cuenta</span>
    </button>
    <br/>
    <br/>
    <br/>
    <mat-divider></mat-divider>
    <button mat-menu-item>
        <mat-icon>info</mat-icon>
        <span>Ayuda</span>
      </button>
      <button mat-menu-item>
        <mat-icon>exit_to_app</mat-icon>
        <span>Cerrar Sesión</span>
      </button>
  </mat-menu>

    <mat-card class="mat_card">  
        <mat-card-content >
          <mat-card-title class="txt-center">Selecciona el monto a pagar</mat-card-title>
          <div class="table">
            <mat-radio-group>
              <mat-radio-button value="p">$5,200.00</mat-radio-button>
              <br/>
              <br/>
              <mat-radio-button value="o">Otra cantidad</mat-radio-button>
                  <input type="number" placeholder="Cantidad" value="">
            </mat-radio-group>
          </div>
          <br/>
          <br/>
          <mat-card-title class="txt-center">Forma de pago</mat-card-title>
         <div class="table">
          <mat-radio-group>
            <mat-radio-button value="p"><mat-icon>credit_card</mat-icon>Tarjeta crédito/débito</mat-radio-button>
            <br/>
            <br/>
            <mat-radio-button value="o"> <mat-icon>description</mat-icon>Referencia de pago</mat-radio-button>
          </mat-radio-group>
         </div>
         <br/>
         <br/>
          <div class="txt-center">
            <button mat-raised-button onclick="location.href='/realiza-pago'">Continuar</button>
          </div>
        </mat-card-content>
    </mat-card>
