import { Component, OnInit } from '@angular/core';

import { PagoService } from '@app/_services/pago.service';
import { FileDownloadService } from '@app/_services/file-download.service';
import { AlertaService } from '@app/_services/alerta.service';
import { Prospecto } from '@app/_models/prospecto';
import { Cargo } from '@app/_models/cargo';

@Component({
  selector: 'app-consulta-pagos',
  templateUrl: './consulta-pagos.component.html',
  styleUrls: ['./consulta-pagos.component.scss']
})
export class ConsultaPagosComponent implements OnInit {
  public prospecto: Prospecto;
  public universidad: string;
  public campus: string;
  public grado: string;
  public periodo: string;
  public saldo: number;
  public cantidadTipo: string;
  public cantidad: number;
  public metodoPago: string;
  public error: string = null;
  public pagosRealizados: boolean = false;
  constructor(
    private pagoService: PagoService,
    private alertaService: AlertaService,
    private fileDownloadService: FileDownloadService
  ) {
    this.prospecto = new Prospecto;
    this.error = null;
    this.pagosRealizados = false;
  }

  ngOnInit() {
    this.pagoService.obtieneProspecto()
      .subscribe(
        data => {
          this.prospecto = data;
          if (data.Cargos.length > 0) {
            let cargo: Cargo = data.Cargos[data.Cargos.length - 1];
            this.universidad = cargo.Campus.InstitucionID;
            this.campus = cargo.Campus.Nombre;
            this.grado = cargo.Grado.Nombre;
            this.periodo = cargo.Ciclo.Nombre;
          }
          if (data.Pagos.length > 0) {
            data.Pagos.forEach(p => {
              if (p.FechaAplicacion != null) {
                this.pagosRealizados = true;
                return;
              };
            });
          }
          this.saldo = data.Saldo;
        });
  }

  public pagar(): void {
    this.error = null;
    let cantidadPagar = this.saldo;

    if (!this.cantidadTipo || !this.metodoPago) {
      this.error = "Debes elegir una cantidad a pagar y un medio de pago.";
      return;
    }

    if (this.cantidadTipo == "o") {
      cantidadPagar = this.cantidad || 0;

      if (isNaN(cantidadPagar)) {
        this.error = "La cantidad a pagar debe ser una cantidad monetaria válida.";
        return;
      };

      if (cantidadPagar < 0) {
        this.error = "La cantidad a pagar no puede ser un monto negativo.";
        return;
      }
      if (cantidadPagar <= 0) {
        this.error = "La cantidad a pagar no puede ser $0.00.";
        return;
      }
      if (cantidadPagar > this.saldo) {
        this.error = "La cantidad a pagar no puede ser un montor mayor que tu saldo.";
        return;
      }
    }

    if (this.metodoPago == "p") {
      this.pagoService.obtieneUrlTarjeta(cantidadPagar)
        .subscribe(
          liga => {
            if (!!liga) {
              if (!!liga.Resultado)
                location.href = liga.Url;
              else
                alert(liga.Mensaje);
            }
          }
        );
    } else if (this.metodoPago == "o") {
      this.pagoService.obtieneFicha(cantidadPagar)
        .subscribe(
          data => {
            if (!!data) {
              this.fileDownloadService.downloadFile(data, "ficha-pago.pdf", "application/pdf");
            }
          }
        );
    };
  };

}
