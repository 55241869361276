import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { Router, ActivatedRoute } from '@angular/router';

import { AuthenticationService } from '@app/_services/authentication.service'
import { NavigationService } from '@app/_services/navigation.service';
import { LoadingScreenService } from '@app/_services/loading-screen.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  usuario: string = "";
  password: string = "";
  returnUrl: string = "";
  error: boolean = false;
  errorMessage: string = "";
  loading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private navigationService: NavigationService,
    private authenticationService: AuthenticationService,
    private loadingScreenService: LoadingScreenService,
  ) {
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    console.log("entrando a login");
    if (this.authenticationService.currentUserValue != null) {
      console.log("navegando a inicio");
      this.router.navigate(['/inicio']);
    }
  }

  login(): void {

    this.loadingScreenService.startLoading();
    this.error = false;
    this.errorMessage = "";

    this.authenticationService.login(this.usuario, this.password)
      .pipe(first())
      .subscribe(
        data => {
          this.navigationService.isToggledMenu.next(true);

          this.router.navigate([this.returnUrl]);
          this.loadingScreenService.stopLoading();
        },
        error => {
          this.error = true;
          this.errorMessage = "Usuario o contraseña inválidos.";
          this.loadingScreenService.stopLoading();
        });
  }

}
