import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeEsMx from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { JwtInterceptor } from './_shared/jwt.interceptor';
import { ErrorInterceptor } from './_shared/error.interceptor';
import { LoadingScreenInterceptor } from './_shared/loading.interceptor';
import { PaginatorI18n } from './_shared/es-paginator-intl';
import {CurrencyPipe} from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select'
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';

import { AdministracionComponent } from './administracion/administracion.component';
import { LoginComponent } from './login/login.component';
import { RecuperaCuentaComponent } from './recupera-cuenta/recupera-cuenta.component';
import { RegistroComponent } from './registro/registro.component';
import { ConsultaPagosComponent } from './consulta-pagos/consulta-pagos.component';
import { RealizaPagoComponent } from './realiza-pago/realiza-pago.component';
import { EstadoCuentaComponent } from './estado-cuenta/estado-cuenta.component';
import { AyudaComponent } from './ayuda/ayuda.component';
import { AdministracionUsuariosComponent } from './administracion-usuarios/administracion-usuarios.component';
import { DetalleUsuarioComponent } from './_components/detalle-usuario/detalle-usuario.component';
import { DetalleProspectoComponent } from './_components/detalle-prospecto/detalle-prospecto.component';
import { AdministracionProspectosComponent } from './administracion-prospectos/administracion-prospectos.component';
import { NavigationBarComponent } from './_components/navigation-bar/navigation-bar.component';
import { CambiaContrasenaComponent } from './cambia-contrasena/cambia-contrasena.component';
import { FooterComponent } from './_components/footer/footer.component';
import { ConciliacionBancariaComponent } from './conciliacion-bancaria/conciliacion-bancaria.component';
import { InicioComponent } from './inicio/inicio.component';
import { ReporteComponent } from './reporte/reporte.component';
import { SideBarComponent } from './_components/side-bar/side-bar.component';
import { AlertaComponent } from './_components/alerta/alerta.component';
import { ActivarComponent } from './activar/activar.component';
import { SignOutComponent } from './sign-out/sign-out.component';
import { RegistroEsperaComponent } from './registro-espera/registro-espera.component';
import { LoadingScreenComponent } from './_components/loading-screen/loading-screen.component';
import { RecuperaCuentaEsperaComponent } from './recupera-cuenta-espera/recupera-cuenta-espera.component';
import { CambiaContrasenaValidarComponent } from './cambia-contrasena-validar/cambia-contrasena-validar.component';
import { PagoProcesadoComponent } from './pago-procesado/pago-procesado.component';

registerLocaleData(localeEsMx);

@NgModule({
  declarations: [
    AppComponent,
    AdministracionComponent,
    LoginComponent,
    RecuperaCuentaComponent,
    RegistroComponent,
    ConsultaPagosComponent,
    RealizaPagoComponent,
    EstadoCuentaComponent,
    AyudaComponent,
    AdministracionUsuariosComponent,
    DetalleUsuarioComponent,
    DetalleProspectoComponent,
    AdministracionProspectosComponent,
    NavigationBarComponent,
    CambiaContrasenaComponent,
    FooterComponent,
    ConciliacionBancariaComponent,
    InicioComponent,
    ReporteComponent,
    SideBarComponent,
    AlertaComponent,
    ActivarComponent,
    SignOutComponent,
    RegistroEsperaComponent,
    LoadingScreenComponent,
    CambiaContrasenaValidarComponent,
    RecuperaCuentaEsperaComponent,
    PagoProcesadoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    FlexLayoutModule,
    RecaptchaModule,  //this is the recaptcha main module
    RecaptchaFormsModule,
    FontAwesomeModule, //this is the module for form incase form validation
    MatToolbarModule,
    MatSelectModule,
    MatIconModule,
    MatDividerModule,
    MatMenuModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCardModule,
    MatTabsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatProgressBarModule,
  ],
  entryComponents: [DetalleUsuarioComponent, DetalleProspectoComponent, AlertaComponent],
  providers: [
    CurrencyPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    { provide: MatPaginatorIntl, useValue: new PaginatorI18n().ObtenerIdioma() },
    { provide: LOCALE_ID, useValue: "es-MX" }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
