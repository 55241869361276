import { Component, OnInit, ViewChild } from '@angular/core';
import {FormGroup, FormControl, FormBuilder, Validators  } from '@angular/forms';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {ConciliacionService } from '@app/_services/conciliacion.service';
import {first } from 'rxjs/operators';
import { Pago } from '@app/_models/pago';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '@app/_services/authentication.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { formatDate } from '@angular/common';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-conciliacion-bancaria',
  templateUrl: './conciliacion-bancaria.component.html',
  styleUrls: ['./conciliacion-bancaria.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-MX' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class ConciliacionBancariaComponent implements OnInit {

  conciliacionForm: FormGroup;
  //listadoConciliacionForm: FormGroup;
  searchControl = new FormControl();
  listadoSearchControl = new FormControl();
  fileTXT:File;
  nombreArchivo = 'Ningún archivo seleccionado';
  banco = '';

  controlBusqueda = new FormControl();
  options: string[] = ['Depósito', 'Santander', 'Banamex'];

  bancos: any[] = [
    {value: 'AZTECA', description: 'Banco azteca'},
    {value: 'BANAMEX', description: 'Banamex'},
    {value: 'OXXO', description: 'Oxxo'},
    {value: 'SANTANDER', description: 'Santander'}
  ];

  displayedColumns: string[] = ['fechaPago', 'fechaAplicacion', 'referencia', 'montoPagado', 'banco', 'aplicado'];

  dataSourceDinamy = new MatTableDataSource<Pago>();
  dataSourceP = new MatTableDataSource<Pago>();

  pageEvent: PageEvent;
  pageEventP: PageEvent;
  pageIndex:number = 0;
  pageSize:number = 10;
  length:number = 0
  pagos:Pago[];

  pageIndexP:number = 0;
  pageSizeP:number = 10;
  lengthP:number = 0
  filtroP: string = "";
  errorMessageP: string = "";

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatPaginator, {static: true}) paginatorP: MatPaginator;

  dateDesde= new Date();
  dateHasta= new Date();
  tipoFecha: number = 0;
  fechaHasta:Date= new Date();
  fechaDesde:Date= new Date();

  selected = true;

  constructor(private conciliacionService:ConciliacionService, private formBuilder: FormBuilder, public dialog: MatDialog, private authenticationService: AuthenticationService) { 

  }

  ngOnInit() {
    
    this.dataSourceDinamy.paginator = this.paginator;
    this.dataSourceP.paginator = this.paginator;

    this.tipoFecha = 0;

    this.conciliacionForm = this.formBuilder.group({
      banco: ['', Validators.required]
    });   
  
  }

  seleccionarArchivo(){
    document.getElementById('upload-file').click();
  }

  adjuntarArchivo(fileInput: any) {
    if (fileInput.target.files.length <= 0){
      this.nombreArchivo = 'Ningún archivo seleccionado';
      this.fileTXT = null;
    } else {
      this.fileTXT = fileInput.target.files[0];
      this.nombreArchivo = this.fileTXT.name;
    }
  
  }

  getDataPagos() {
    this.getPagos(this.pageSizeP, this.pageIndexP + 1);
    this.getCount();
  }

  getPagos(nRegistros:number, nPagina:number) {
    
    this.conciliacionService.get(nRegistros, nPagina, this.filtroP, this.tipoFecha, this.fechaDesde, this.fechaHasta)
    .pipe(first())
    .subscribe(
      data => {
        this.dataSourceP = new MatTableDataSource<Pago>(data.Data);
      },
      error => {
        this.errorMessageP = "Error al obtener la lista de pagos";
      });
  }

  getCount():number{

    this.conciliacionService.getCount(this.filtroP, this.tipoFecha, this.fechaDesde, this.fechaHasta)
    .pipe(first())
    .subscribe(
      dataCount => {
        this.lengthP = dataCount;
        return dataCount;
      },
      error => {
        this.errorMessageP = "Error al obtener el total de registros";
      });

    return 0;
  }

  importarArchivo() {

    let fileFD = new FormData();

    if (this.fileTXT != null)
    {
      fileFD.append("archivoTXT", this.fileTXT, this.fileTXT.name);

      this.pagos= null;

      this.dataSourceDinamy = new MatTableDataSource<Pago>(null);
      this.pageIndex = 0;
      this.length = 0;

      let prospectoId = this.authenticationService.currentUserValue.ProspectoID;

      this.conciliacionService.importar(fileFD, this.banco, prospectoId)
      .pipe(first())
      .subscribe(
      data => {      

         if (data.Success){
           if (data.Data != null){

            this.pagos = data.Data;
            this.getData();
            if (data.Message != null){
              const dialogData = new ConfirmDialogModel('Información', data.Message, false);    
              this.dialog.open(AlertaComponent, {
                maxWidth: "500px",
                data: dialogData
              });
            }

           } else {

            const dialogData = new ConfirmDialogModel('Error', 'La solicitud no devolvió resultados.', false);    
            this.dialog.open(AlertaComponent, {
              maxWidth: "400px",
              data: dialogData
            });

           }

         } else {

          const dialogData = new ConfirmDialogModel('Error', data.Message, false);    
          this.dialog.open(AlertaComponent, {
            maxWidth: "500px",
            data: dialogData
          });

         }

      },
      error => {
        console.log('error', error);
      });
    }
  }

  getData(){
    this.length = this.pagos.length; 
    this.dataSourceDinamy = new MatTableDataSource<Pago>(this.pagos.slice((this.pageIndex * this.pageSize) , ((this.pageIndex + 1) * this.pageSize)));
  }

  public getServerData(event?:PageEvent){

    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;     

    this.getData();

    return event;
  }

  public getServerDataP(event?:PageEvent){

    this.pageSizeP = event.pageSize;
    this.pageIndexP = event.pageIndex;     

    this.getDataPagos();

    return event;
  }

  filterText(){
    this.pageIndexP = 0;
    this.getDataPagos();
  }

  buscar(){

    if (this.tipoFecha == 0){
      const dialogData = new ConfirmDialogModel('Aviso', 'De favor seleccione un tipo de fecha para realizar la búsqueda', false);    
      this.dialog.open(AlertaComponent, {
        maxWidth: "500px",
        data: dialogData
      });
      return;
    }

    if (this.dateDesde == null || this.dateHasta == null) {
      const dialogData = new ConfirmDialogModel('Error', 'El formato de las fechas son incorrectas', false);    
      this.dialog.open(AlertaComponent, {
        maxWidth: "400px",
        data: dialogData
      });
      return;
    }

    let dateDesde_ = formatDate(this.dateDesde,'yyyy-MM-dd', 'es-MX' );
    this.fechaDesde = new Date(dateDesde_);
    this.fechaDesde.setDate(this.fechaDesde.getDate() + 1);

    let dateHasta_ = formatDate(this.dateHasta,'yyyy-MM-dd', 'es-MX' );
    this.fechaHasta = new Date(dateHasta_);
    this.fechaHasta.setDate(this.fechaHasta.getDate() + 1);
    
    this.filterText();
  }
}
