<mat-toolbar color="white" class="main-navbar">
  <mat-toolbar-row>

    <a routerLink="/" class="brand-title">Mi Caja Aliat</a>

    <div fxFlex fxLayout fxLayoutAlign="flex-end">
      <ul fxLayout fxLayoutGap="20px" class="navigation-items" fxHide fxShow.xs fxShow.sm>
        <li>
          <button mat-icon-button color="default" aria-label="Menú" matTooltip="Mostrar el menú de navegación" (click)="toggleMenu()">
            <mat-icon>menu</mat-icon>
          </button>
        </li>
      </ul>

      <ul fxLayout fxHide.xs fxHide.sm fxLayoutGap="20px" class="navigation-items" [hidden]="!isAuthenticated">
        <li>
          <button mat-raised-button color="accent" aria-label="Cerrar sesión" matTooltip="Terminar la sesión del usuario" (click)="logout()">
            Cerrar Sesión
            <i class="fas fa-sign-out-alt"></i>
          </button>
        </li>
      </ul>

      <ul fxLayout fxHide.xs fxHide.sm fxLayoutGap="20px" class="navigation-items" [hidden]="isAuthenticated">
        <li [hidden]="!isLogin">
          <button mat-raised-button color="accent" routerLink="/registro">Crear Cuenta</button>
        </li>
        <li [hidden]="isLogin">
          <button mat-raised-button color="accent" routerLink="/login">Iniciar Sesión</button>
        </li>
      </ul>

    </div>
  </mat-toolbar-row>
  <div class="separador-superior">
    <div class="seccion-separador bg-celeste"></div>
    <div class="seccion-separador bg-ambar"></div>
    <div class="seccion-separador bg-naranja"></div>
    <div class="seccion-separador bg-azul"></div>
    <div class="seccion-separador bg-rojo"></div>
  </div>
</mat-toolbar>
