    <mat-card-title class="card-title">{{data.operacion}} usuario</mat-card-title>
    <form class="example-form" name="detalleUsuarioForm" id="detalleUsuarioForm" [formGroup]="detalleUsuarioForm" autocomplete="off">

        <mat-form-field class="input-full-width">
            <mat-label>Número de empleado</mat-label>

            <input matInput type="number" id="numEmpleado" name="numEmpleado" formControlName="numEmpleado" (input)='verify()' [(ngModel)]='data.user.NumeroEmpleado' required>
         
        </mat-form-field>
        <br>

        <mat-form-field  class="input-full-width" >
            <mat-label>Área</mat-label>
            <mat-select  id="area" name="area" formControlName="area" (ngModelChange)='verify()' [(ngModel)]="data.user.Area_.AreaID" required>
              <mat-option *ngFor="let area of areas" [value]="area.AreaID">
                {{area.Nombre}}
              </mat-option>
            </mat-select>
        </mat-form-field>
        <br>

        <mat-form-field class="input-full-width">
            <mat-label>Nombre</mat-label>
            <input matInput type="text" id="nombre" name="nombre" formControlName="nombre" (input)='verify()'  [(ngModel)]='data.user.Nombre' required>
        </mat-form-field>
        <br>

        <mat-form-field class="input-full-width">
            <mat-label>Apellido paterno</mat-label>
            <input matInput type="text" id="apellidoPaterno" name="apellidoPaterno" formControlName="apellidoPaterno" (input)='verify()' [(ngModel)]='data.user.ApellidoPaterno' required>
        </mat-form-field>
        <br>
        
        <mat-form-field class="input-full-width">
            <mat-label>Apellido materno</mat-label>
            <input matInput type="text" id="apellidoMaterno" name="apellidoMaterno" formControlName="apellidoMaterno" (input)='verify()' [(ngModel)]='data.user.ApellidoMaterno'>
        </mat-form-field>
        <br>

        <mat-form-field class="input-full-width">
            <mat-label>Correo electrónico</mat-label>
            <input matInput placeholder="correo@example.com" formControlName="email" id="email" name="email" (input)='verify()' [(ngModel)]='data.user.Correo' autocomplete="off" required  >
            <div *ngIf="formControls.email.errors">
                <mat-error *ngIf="formControls.email.errors.email"> El correo electrónico no tiene un formato correcto. </mat-error>
            </div>          
        </mat-form-field>
        <br>
    
        <mat-form-field  class="input-full-width">
            <mat-label>Campus</mat-label>
            <mat-select #mySelCampus name="campus" formControlName="campus" [(value)]="selectedCampus" id="campus" (ngModelChange)='verify()'  [(ngModel)]="data.user.IdsCampus" required  multiple>
                <mat-option  #allSelectedCampus  (click)="toggleAllSelectionCampus()" [value]="'1'"><label>Todos</label></mat-option>
                <mat-option *ngFor="let campus of campuses" [value]="campus.CampusID" 
                (click)="tosslePerOneCampus(allSelectedCampus.viewValue)"> 
                {{campus.CampusID}} - {{campus.Nombre}}
            </mat-option>
        </mat-select>
        </mat-form-field>          
        <br>
        
        <mat-form-field class="input-full-width">
            <mat-label>Privilegios del sistema</mat-label>
            <mat-select name="rol" formControlName="rol" id="rol" (ngModelChange)='verify()' [(ngModel)]="data.user.IdsRoles" required  multiple>
              <mat-option *ngFor="let rol of roles" [value]="rol.RolID">{{rol.Nombre}}</mat-option>
            </mat-select>
        </mat-form-field>

        <br>
        <br>

        <button mat-raised-button color="primary" class="" (click)="onSubmit()" [disabled]="!enableBtn" >Guardar</button>
        &nbsp; 
        <button mat-raised-button color="warn" class="" (click)="onNoClick()" >{{textBtnCancelar}}</button>

        <div *ngIf="error">
            <br>
            <mat-card class='card-danger'>{{errorMessage}}</mat-card>
        </div>

        <div *ngIf="correcto">
            <br>
            <mat-card class='card-success'>{{message}}</mat-card>
        </div>
       
       
        <br>
        <br>

    </form>

