<mat-card class="recupera-card">  
  <mat-card-title class="card-title">Recupera cuenta</mat-card-title>
  <br>
  <div class="center">
    <label class='text-center'>

      Para recuperar tu contraseña escribe la dirección de correo electrónico y fecha de nacimiento que registraste.  <br>
      Recibirás un correo electrónico con los pasos a seguir.

    </label>
  </div>
  <br>

  <form class="example-form" name="recuperarCuentaForm" id="recuperarCuentaForm" [formGroup]="recuperarCuentaForm">

    <mat-form-field class="example-full-width">
      <mat-label>Correo electrónico</mat-label>
      <input matInput placeholder="correo@example.com" formControlName="email" id="email" name="email" [(ngModel)]='correo' required>
      <div *ngIf="formControls.email.errors">
        <mat-error *ngIf="formControls.email.errors.email"> El correo electrónico no tiene un formato correcto. </mat-error>
      </div>   
    </mat-form-field>

    <br>

    <mat-form-field class="example-full-width">
      <mat-label>Fecha de nacimiento</mat-label>
      <input matInput [min]="minDate" [max]="maxDate" placeholder="dd/mm/yyyy" 
       [(ngModel)]='dateNac'
      [matDatepicker]="picker" formControlName="fechaNac" id="fechaNac" name="fechaNac" >
      <div *ngIf="dateError">
        <mat-error *ngIf="dateError"> La fecha ingresada no tiene un formato correcto. </mat-error>
      </div>   
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <br>

    <div #recaptcha></div>

    <br>
    <div class="text-center">
      <button class="color-btn" mat-raised-button (click)="onSubmit()" [disabled]="!enableBtn"  >Continuar</button>
    </div>
  </form>

</mat-card>