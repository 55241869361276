<mat-card class="admin-user-card">
    <mat-card-title class="card-title">Administración de usuarios</mat-card-title>

    <form (keydown.enter)="$event.preventDefault()" class="example-form" name="adminUserForm" id="adminUserForm" >

    <button mat-raised-button class="color-btn" (click)="agregarUsuario()" >+ Agregar</button>
        
    <br>
    <br>

    <mat-form-field>
      <mat-label>Filtro de búsqueda:</mat-label>
      <input matInput  [(ngModel)]="filtro"  (input)="filterText()" id="filter" name="filter">
    </mat-form-field>

    </form>

    <div class="example-container mat-elevation-z8">

      <mat-table  [dataSource]="dataSource" >
    
          <ng-container matColumnDef="NumeroEmpleado">
            <mat-header-cell *matHeaderCellDef> Empleado </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="Número empleado"> {{element.NumeroEmpleado}} </mat-cell>
          </ng-container>      
         
          <ng-container matColumnDef="Area">
            <mat-header-cell *matHeaderCellDef> Área </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="Área"> {{element.Area_.Nombre}} </mat-cell>
          </ng-container>
             
          <ng-container matColumnDef="Nombre">
            <mat-header-cell *matHeaderCellDef> Nombre </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="Nombre"> {{element.Nombre}} {{element.ApellidoPaterno}} {{element.ApellidoMaterno}} </mat-cell>
          </ng-container>
          
          <ng-container matColumnDef="Correo">
            <mat-header-cell *matHeaderCellDef> Correo </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="Correo"> {{element.Correo}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Privilegio">
            <mat-header-cell *matHeaderCellDef> Privilegios </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="Privilegios"> {{element.Roles}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Opciones">
            <mat-header-cell *matHeaderCellDef> Opciones </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="Opciones">
                <button mat-icon-button color="basic" aria-label="Editar" (click)="editarUsuario(element)"  matTooltip="Editar"  matTooltipPosition="right"><mat-icon>edit</mat-icon></button>
                <button mat-icon-button color="accent" aria-label="Eliminar" matTooltip="Eliminar" (click)="eliminarUsuario(element)"  matTooltipPosition="right"><mat-icon>delete_forever</mat-icon></button>                  
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;">
          </mat-row>
        </mat-table> 
      
        <mat-paginator #paginator
          [length]="length"
          [pageIndex]="pageIndex"
          [pageSize]="pageSize"
          [pageSizeOptions]="[5, 10, 20]"
          (page)="pageEvent = getServerData($event)"
          >
        </mat-paginator>

      </div>    
    
</mat-card>
