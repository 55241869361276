<mat-card class="mat_card">         
          <mat-card-title class="card-title">Estado de cuenta</mat-card-title>
          <br/>
          <div class="col center">
          <mat-card class="card-table">
            <mat-card-content >
              <table>
                <tr>
                    <td><label>+ Inscripción</label></td>
                    <br/>
                    <td><label>${{ cargos | number : '1.2-2'}}</label></td>
                </tr>
                <br/>
                <tr>
                    <td><label>- Pagos</label></td>
                    <br/>
                    <td><label>${{ pagos | number : '1.2-2'}}</label></td>
                </tr>
                <br/>
                <mat-divider></mat-divider>
                <br/>
                <tr>
                  <td><label>Saldo</label></td>
                  <br/>
                  <td><label>${{ prospecto.Saldo | number : '1.2-2'}}</label></td>
              </tr>
              </table>
            </mat-card-content>
          </mat-card>
        </div>
        <br/>
        <br/>
        <div class="example-container mat-elevation-z8">
          <mat-table [dataSource]="dataSource" matSort>
          
              <ng-container matColumnDef="area">
                <mat-header-cell *matHeaderCellDef>Fecha de Pago </mat-header-cell><br>
                <mat-cell *matCellDef="let element" data-label="Fecha  de Pago"> {{element.fechaOp | date :'mediumDate'}} </mat-cell>
              </ng-container>
          
              <ng-container matColumnDef="nombre">
                <mat-header-cell *matHeaderCellDef>Fecha de Aplicación </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Fecha de Aplicacion"> {{(element.fechaApl | date :'mediumDate') || "Pendiente" }} </mat-cell>
              </ng-container>
          
              <ng-container matColumnDef="correo">
                <mat-header-cell *matHeaderCellDef> Concepto </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Concepto"> {{element.concepto}} </mat-cell>
              </ng-container>
    
              <ng-container matColumnDef="cargo">
                <mat-header-cell *matHeaderCellDef > Cargo (MXN) </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Importe"> {{element.cargo | number : '1.2-2'}} </mat-cell>
              </ng-container>
    
              <ng-container matColumnDef="abono">
                <mat-header-cell *matHeaderCellDef > Abono (MXN) </mat-header-cell>
                  <mat-cell *matCellDef="let element" data-label="Abono"> {{element.abono | number : '1.2-2'}} </mat-cell>
                
              </ng-container>

              <ng-container matColumnDef="metodo">
                <mat-header-cell *matHeaderCellDef > Método de Pago </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Metodo de Pago"  > {{element.metodoPago}} </mat-cell>
              </ng-container>
    
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;">
              </mat-row>
            </mat-table> 
           </div>    
         <br/>
    </mat-card>

