<mat-card *ngIf="enable_" class="recupera-card">
    <mat-card-title class="card-title"> {{titulo}} </mat-card-title>

    <form class="example-form" name="cambioPasswordForm" id="cambioPasswordForm" [formGroup]="cambioPasswordForm">
       
        <mat-form-field class="example-full-width">
          <mat-label>Contraseña</mat-label>
          <input matInput type="password" id="password" name="password"
          (focus)="mostrarDescripcion()" (focusout)="ocultarDescripcion()"
           formControlName="password" required
          matTooltip="La contraseña debe tener al menos 8 caractéres, mayúsculas, minúsculas y al menos un número o caracter especial"
          (ngModelChange)="updateProgressBar()" >
          <div *ngIf="fortalezaError">
            <mat-error *ngIf="fortalezaError">La contraseña no cumple con los requisitos de seguridad </mat-error>
          </div>            
        </mat-form-field>
        <div *ngIf="mostrarDescripcionPass">
            <mat-label  class="registro-label-pass">Debe contener al menos 8 caracteres, mayúsculas, minúsculas y al menos un número o caracter especial</mat-label>       
          </div> 
        <br>

        <mat-progress-bar *ngIf="containsPssword"
        mode="determinate"
        class="example-full-width"
        [color]="colorForce"        
        [value]="valueForce"
        [bufferValue]="bufferValue">
        </mat-progress-bar>


        <br>

        <mat-form-field class="example-full-width">          
            <input matInput placeholder="Vuelve a escribir tu contraseña" type="password" id="confirmPassword" name="confirmPassword" 
            formControlName="confirmPassword" [errorStateMatcher]="matcher">
            <mat-error *ngIf="cambioPasswordForm.hasError('notSame')">
                La contraseña no coincide
             </mat-error>  
        </mat-form-field>

        <br>
        
        <div #recaptcha *ngIf="showInput"></div>
        
        <br>
        <div class="text-center">
            <button class="color-btn" mat-raised-button  (click)="cambiarContrasena()">Cambiar contraseña</button>
        </div>
        
    </form>

</mat-card>


<mat-card *ngIf="!enable_" class="recupera-card">
    <br>
    <mat-card-title class=""><div class="titulo-danger center">¡Enlace inválido!</div></mat-card-title>
    <br>
    <div class="texto-informativo">
        <label class=''>
        El enlace de recuperación de contraseña ya no es válido. 
        <br>
        <br>
        Si requieres recuperar tu contraseña, de favor ingresa <a id="link" href="#" routerLink="/recupera-cuenta" >aquí</a>.
        <br>
        </label>           
    </div>
</mat-card>
