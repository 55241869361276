import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { Grado } from '../../_models/grado';
import { Campus } from '../../_models/campus';
import { Combo } from '../../_models/combo';
import { CargoOperation } from '../../_models/cargoOperation';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GradoService } from '@app/_services/grado.service';
import { CampusService } from '@app/_services/campus.service';
import { CargoService } from '@app/_services/cargo.service';
import { CicloService } from '@app/_services/ciclo.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Cargo } from '@app/_models/cargo';
import { Ciclo } from '@app/_models/ciclo';
import { catchError } from 'rxjs/operators';
import {Observable} from 'rxjs';
import { ProspectoService } from '@app/_services/prospecto.service';
import {map, startWith} from 'rxjs/operators';
import { CommonModule, CurrencyPipe} from '@angular/common';
import { AuthenticationService } from '@app/_services/authentication.service';

@Component({
  selector: 'app-detalle-prospecto',
  templateUrl: './detalle-prospecto.component.html',
  styleUrls: ['./detalle-prospecto.component.scss']
})
export class DetalleProspectoComponent implements OnInit, AfterViewInit {
  formattedAmount;
  amount;
  detalleProspectoForm: FormGroup;
  disabled_ = false;
  enableBtn = false;

  disableCorreo = true;

  idCargo = null;
  guardando = false;
  cargandoCampus = null;
  cargandoGrado = null;
  cargandoPeriodo = null;
  hayErrores = false;

  selectedValue = "";
  value: string;
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;

  option = []
  campus: Campus[] = [];
  grados: Grado[] = [];
  periodos: Ciclo[] = [];

  idUsuario: number;

  error: boolean = false;
  errorMessage: string = "";


  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<DetalleProspectoComponent>,
    private gradoService: GradoService,
    private campusService: CampusService,
    private cicloService : CicloService,
    private cargoService: CargoService,
    private prospectoService: ProspectoService,
    private currencyPipe : CurrencyPipe,
    private authenticationService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: CargoOperation) { }

    transformAmount(element){
      this.formattedAmount = this.currencyPipe.transform(this.formattedAmount, '$');
  
      element.target.value = this.formattedAmount;
  }

  selectedCampus(event) {
    this.detalleProspectoForm.get('grado').setValue('');  
    this.selectedValue = event.source.value;
    const setVal = this.option.find( op => op.CampusID === this.selectedValue)
    this.myControl.setValue(this.selectedValue + " - " + setVal.Nombre)
    this.prospectoService.consultarNiveles(this.selectedValue).then(grados => {
      this.grados = grados;
    }).catch(err => {
      console.log("Error" + err);
    });  

 }

 /*
 selectedCampus() {
  var CampusID = this.detalleProspectoForm.get('campus').value;
  this.detalleProspectoForm.get('grado').setValue('');  
  this.prospectoService.consultarNiveles(CampusID).then(grados => {
    this.grados = grados;
  }).catch(err => {
    console.log("Error" + err);
  });  

} */
 
  ngOnInit() {
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;

    this.hayErrores = false;
    this.guardando = false;
    this.cargandoCampus = "(Cargando ...)";
    this.cargandoGrado = "(Cargando ...)";
    this.cargandoPeriodo = "(Cargando ...)";

    this.selectedValue = "";
    this.gradoService.consultaGrados().then(grados => {
      this.grados = grados;
      this.cargandoCampus = null;
    }).catch(err => {
      this.hayErrores = true;
      this.cargandoCampus = "(Error)";
      console.log("Error" + err);
    });
  
    this.campusService.consultaCampus(this.idUsuario).then(campus => {
      debugger
      this.campus = campus;
      this.campus.forEach((c, idx) => {
        this.option.push({CampusID:c.CampusID, Nombre:c.Nombre})
      });
      this.cargandoGrado = null;
    }).catch(err => {
      this.hayErrores = true;
      this.cargandoGrado = "(Error)";
      console.log("Error" + err);
    });

    this.cicloService.consultaCiclo().then(ciclos => {
      this.periodos = ciclos;
      this.cargandoPeriodo = null;
    }).catch(err => {
      this.hayErrores = true;
      this.cargandoPeriodo = "(Error)";
      console.log("Error" + err);
    });

    console.log("Operacion: " + this.data.Operation);
    if (this.data.Operation === 'Crear') {
      this.disableCorreo = false;
    } else {
      this.disableCorreo = true;
    }

    console.log("disableCorreo: " + this.disableCorreo);
    this.detalleProspectoForm = this.formBuilder.group({
      correo: [{ value: '', disabled: this.disableCorreo, }, Validators.required],
      campus: [{ value: '', disabled: this.disabled_ }, Validators.required],
      grado: [{ value: '', disabled: this.disabled_ }, Validators.required],
      periodo: [{ value: '', disabled: this.disabled_ }],
      oportunidad: [{ value: '', disabled: this.disabled_ }, Validators.required],
      monto: [{ value: '', disabled: this.disabled_, }, Validators.required],

    });


    this.filteredOptions = this.myControl.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value))
    );


  }

  
  ngAfterViewInit() {

    console.log("Cargo idCargo : " + this.data.Cargo.CargoID);

    console.log("disableCorreo : " + this.disableCorreo);

    //console.log("this.data.Cargo.IDCargo: " + JSON.stringify(this.data.Cargo.IDCargo));


    console.log("Operacion: " + this.data.Operation);
    if (this.data.Operation === 'Editar') {
  
      this.idCargo = this.data.Cargo.CargoID;
      this.detalleProspectoForm.get('correo').setValue(this.data.Cargo.Correo);
      this.detalleProspectoForm.get('campus').setValue(this.data.Cargo.Campus.CampusID);
      this.myControl.setValue(this.data.Cargo.Campus.CampusID + " - " + this.data.Cargo.Campus.Nombre)
      this.detalleProspectoForm.get('grado').setValue(this.data.Cargo.Grado.GradoID);
      if(this.data.Cargo.Ciclo != null){
        this.detalleProspectoForm.get('periodo').setValue(this.data.Cargo.Ciclo.CicloID);
      }
      
      this.detalleProspectoForm.get('monto').setValue(this.data.Cargo.Monto);
      var currency = this.detalleProspectoForm.get('monto').value
      var Monto = '$' + currency.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      this.detalleProspectoForm.get('monto').setValue(Monto);

      this.detalleProspectoForm.get('oportunidad').setValue(this.data.Cargo.NumeroOportunidad);


      this.disableCorreo = true;
    }
    else {
      this.disableCorreo = false;

    }




  }




  cancelar(): void {

    console.log('Monto:' + this.detalleProspectoForm.get('monto').value);
    console.log('oportunidad:' + this.detalleProspectoForm.get('oportunidad').value);


    console.log('Cancelar:' + this.idCargo);
    this.dialogRef.close();
  }

  aceptar(): void {
    console.log('Aceptar');
    
    this.error = false;
    
   
    //if(!this.guardando){
      this.guardando = true;
      console.log('Guardando');
      var cargo = new Cargo();
      cargo.UsuarioCreacion = this.idUsuario;
      cargo.CargoID = this.idCargo;
      cargo.Correo = this.detalleProspectoForm.get('correo').value;
      cargo.Campus = new Campus();
      cargo.Campus.CampusID = this.detalleProspectoForm.get('campus').value;
  
      var grado  = this.detalleProspectoForm.get('grado').value;
  
      if(grado != null){
        cargo.Grado = new Grado();
        cargo.Grado.GradoID = this.detalleProspectoForm.get('grado').value;
      }
      var currency = this.detalleProspectoForm.get('monto').value
      cargo.Monto = Number(currency.replace(/[^0-9\.-]+/g,""));
      cargo.NumeroOportunidad = this.detalleProspectoForm.get('oportunidad').value;
  
      var ciclo = this.detalleProspectoForm.get('periodo').value;
      if (ciclo) {
        cargo.Ciclo = {
          CicloID: ciclo,
          Clave: '',
          Nombre: ''
        }
      }
  
  
      this.cargoService.crearCargo(cargo).then(res => {
        if (res.Exito) {
          this.dialogRef.close();
        }else{
          this.error = true;
          this.errorMessage =  res.Mensaje;

        }
        this.guardando = false;
      }).catch(err => {
        console.log("Error" + err);
      }).finally(() => {
        this.guardando = false;
      });

    //}
    

    console.log('Cancelar:' + JSON.stringify(this.detalleProspectoForm.get('campus').value));

  }

  verify() {

    if (this.detalleProspectoForm.invalid) {
      this.enableBtn = false;
    } else {
      this.enableBtn = true;
    }
  }

  private _filter(value): string[] {

    const filterValue = value.toLowerCase();
    const resp =  this.option.filter(option => option.Nombre.toLowerCase().includes(filterValue) ||
    option.CampusID.toLowerCase().includes(filterValue) );

    if(resp.length == 1){
      resp.forEach((c, idx) => {
        this.detalleProspectoForm.controls.campus.setValue(c.CampusID.toString())
      });
    }
    
    //console.log(resp)
    return resp
  }


}



