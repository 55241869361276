import { Component, OnInit,ElementRef,ViewChild } from '@angular/core';
import {FormControl} from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {  MAT_MOMENT_DATE_FORMATS,  MomentDateAdapter,  MAT_MOMENT_DATE_ADAPTER_OPTIONS, } from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { MatOption } from '@angular/material/core';
import { GradoService } from '@app/_services/grado.service';
import { CampusService } from '@app/_services/campus.service';
import { Campus } from '@app/_models/campus';
import { Grado } from '@app/_models/grado';
import { TipoFecha } from '@app/_models/tipoFecha';
import { Reporte } from '@app/_models/reporte';
import { ReporteService } from '@app/_services/reporteService';
import { MatSelect } from '@angular/material/select';
import { FileDownloadService } from '@app/_services/file-download.service';
import { AuthenticationService } from '@app/_services/authentication.service';

@Component({
  selector: 'app-reporte',
  templateUrl: './reporte.component.html',
  styleUrls: ['./reporte.component.scss'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'es-MX'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
  
})
export class ReporteComponent implements OnInit {
  formControl = new FormControl();
  fechasList: TipoFecha[] = [{TipoID :1, Nombre : "Fecha de creación"}, 
                              {TipoID : 2, Nombre :"Fecha de pago"},
                              {TipoID : 3, Nombre :"Fecha de aplicación"}];
  minDate: Date;
  maxDate: Date;

  campus: Campus[] = [];
  grados: Grado[] = [];
  instituciones : string [] = [];

  selectedFechas :TipoFecha[];
  selectedCampus : Campus [];
  selecetedGrados : Grado [];
  selectedInstituciones : string [];

  cargandoCampus = null;
  cargandoGrado = null; 
  hayErrores = false;

  todos = false;

  idUsuario: number;

  reporteForm: FormGroup;
  @ViewChild('allSelectedFecha') private allSelectedFecha: MatOption;
  @ViewChild('mySelFecha') mySelFecha: MatSelect;
  @ViewChild('allSelectedInstitucion') private allSelectedInstucion: MatOption;
  @ViewChild('mySelInstitucion') mySelInstitucion: MatSelect;
  @ViewChild('allSelectedCampus') private allSelectedCampus: MatOption;
  @ViewChild('mySelCampus') mySelCampus: MatSelect;
  @ViewChild('allSelectedGrado') private allSelectedGrado: MatOption;
  @ViewChild('mySelGrado') mySelGrado: MatSelect;


  


  constructor(private formBuilder: FormBuilder, 
    private gradoService: GradoService,
    private campusService: CampusService,
    private reporteService : ReporteService,
    private authenticationService: AuthenticationService,
    private fileDownloadService: FileDownloadService) {  
    const currentYear = new Date().getFullYear();
    const currentMoth = new Date().getMonth();
    const currentDay = new Date().getDay();
    this.minDate = new Date(currentYear - 1, currentMoth, currentDay);
    this.maxDate = new Date();
  }

  ngOnInit() {
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;
    this.cargandoCampus = "(Cargando ...)";
    this.cargandoGrado = "(Cargando ...)";


    this.gradoService.consultaGrados().then(grados => {
      this.grados = grados;
      this.cargandoCampus = null;
    }).catch(err => {
      this.hayErrores = true;
      this.cargandoCampus = "(Error)";
      console.log("Error" + err);
    });




    this.campusService.consultaInstituciones(this.idUsuario).then(instituciones => {
      this.instituciones = instituciones;
      this.cargandoGrado = null;
    }).catch(err => {
      this.hayErrores = true;
      this.cargandoGrado = "(Error)";
      console.log("Error" + err);
    });    


    var ids = ["0"];


    this.reporteForm = this.formBuilder.group({
      tipoFecha :['', Validators.required],
      fechaInicio: ['',Validators.required],
      fechaFin: ['', Validators.required],
      institucion: [''],
      campus: [''],
      grado: [''],
      formato: [''],
      userType: new FormControl('')
    });
  

  }



  tosslePerOneFechas(all){ 
    if (this.allSelectedFecha.selected) {  
     this.allSelectedFecha.deselect();
     return false;
    }
   if(this.reporteForm.controls.userType.value.length==this.fechasList.length)
     this.allSelectedFecha.select();
 
 }


 tosslePerOneInstituciones(all){ 
  if (this.allSelectedInstucion.selected) {  
   this.allSelectedInstucion.deselect();
   return false;
  }
 if(this.reporteForm.controls.userType.value.length==this.instituciones.length)
   this.allSelectedInstucion.select();

} 



tosslePerOneCampus(all){ 
  if (this.allSelectedCampus.selected) {  
   this.allSelectedCampus.deselect();
   return false;
  }
 if(this.reporteForm.controls.userType.value.length==this.campus.length)
   this.allSelectedCampus.select();

} 

tosslePerOneGrado(all){ 
  if (this.allSelectedGrado.selected) {  
   this.allSelectedGrado.deselect();
   return false;
  }
 if(this.reporteForm.controls.userType.value.length==this.grados.length)
   this.allSelectedGrado.select();

} 

toggleAllSelectionFechas() {
  
  if (this.allSelectedFecha.selected) {
    this.mySelFecha.options.forEach( (item : MatOption) => item.select());
    this.todos = true;
  } else {
    this.mySelFecha.options.forEach( (item : MatOption) => {item.deselect()});
  }
 // this.mySelFecha.close();
}


toggleAllSelectionInstituciones() {
if (this.allSelectedInstucion.selected) {
  this.mySelInstitucion.options.forEach( (item : MatOption) => item.select());

} else {
  this.mySelInstitucion.options.forEach( (item : MatOption) => {item.deselect()});
}
//this.mySelInstitucion.close();
}


toggleAllSelectionCampus() {
  if (this.allSelectedCampus.selected) {
    this.mySelCampus.options.forEach( (item : MatOption) => item.select());
  
  } else {
    this.mySelCampus.options.forEach( (item : MatOption) => {item.deselect()});
  }
 }
  

 toggleAllSelectionGrado() {
  if (this.allSelectedGrado.selected) {
    this.mySelGrado.options.forEach( (item : MatOption) => item.select());
  
  } else {
    this.mySelGrado.options.forEach( (item : MatOption) => {item.deselect()});
  }
 }
   




onSelectInstituciones(entrando) {

  if(!entrando){
    console.log("Cargando...");    
    this.campusService.consultaCampusXInstituciones(this.selectedInstituciones, this.idUsuario).then(campus => {
      this.campus = campus;
      this.cargandoGrado = null;
      this.selectedCampus = [];
      this.reporteForm.get('campus').setValue(this.selectedCampus);
    }).catch(err => {
      this.hayErrores = true;
      this.cargandoGrado = "(Error)";
      console.log("Error" + err);
    });



  }




}



  generar(): void {

    
    var reporte = new Reporte();
    console.log("Fechas " + this.reporteForm.get('tipoFecha').value);

    reporte.selectedFechas = this.reporteForm.get('tipoFecha').value;


    reporte.FechaInicial = this.reporteForm.get('fechaInicio').value;
    reporte.FechaFinal = this.reporteForm.get('fechaFin').value;
    reporte.selectedInstituciones = this.reporteForm.get('institucion').value;
    reporte.selectedCampus = this.reporteForm.get('campus').value;
    reporte.selectedGrados = this.reporteForm.get('grado').value;
    reporte.formato = this.reporteForm.get('formato').value;
    reporte.idUsuario = this.idUsuario;

    console.log("Reporte " + JSON.stringify(reporte));

    


    this.reporteService.obtieneReporte(reporte)
    .subscribe(
      data => {
        if (!!data) {
          this.fileDownloadService.downloadFile(data, "reporte.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
        }
      }
    );
    console.log('Aceptar');
  }



  
  private base64toBlob(base64Data: string, contentType: string): Blob {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);
  
    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);
  
      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  
  get f() { return this.reporteForm.controls; }

  



}
