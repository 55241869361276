import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_shared/auth.guard';

import { AdministracionComponent } from './administracion/administracion.component';
import { InicioComponent } from './inicio/inicio.component';
import { LoginComponent } from './login/login.component';
import { RecuperaCuentaComponent } from './recupera-cuenta/recupera-cuenta.component';
import { CambiaContrasenaComponent } from './cambia-contrasena/cambia-contrasena.component';
import { CambiaContrasenaValidarComponent } from './cambia-contrasena-validar/cambia-contrasena-validar.component';
import { RegistroComponent } from './registro/registro.component';
import { ConsultaPagosComponent } from './consulta-pagos/consulta-pagos.component';
import { RealizaPagoComponent } from './realiza-pago/realiza-pago.component';
import { PagoProcesadoComponent } from './pago-procesado/pago-procesado.component';
import { EstadoCuentaComponent } from './estado-cuenta/estado-cuenta.component';
import { AyudaComponent } from './ayuda/ayuda.component';
import { AdministracionUsuariosComponent } from './administracion-usuarios/administracion-usuarios.component';
import { AdministracionProspectosComponent } from './administracion-prospectos/administracion-prospectos.component';
import { ConciliacionBancariaComponent } from './conciliacion-bancaria/conciliacion-bancaria.component';
import { ReporteComponent } from './reporte/reporte.component';
import { ActivarComponent} from './activar/activar.component';
import { SignOutComponent } from './sign-out/sign-out.component';
import { RegistroEsperaComponent} from './registro-espera/registro-espera.component';
import { RecuperaCuentaEsperaComponent} from './recupera-cuenta-espera/recupera-cuenta-espera.component';


const routes: Routes = [{ path: '', redirectTo: 'inicio', pathMatch: 'full' },
  { path: 'inicio', component: InicioComponent, canActivate: [AuthGuard] },
  { path: 'administracion', component: AdministracionComponent, canActivate: [AuthGuard], data: { roles: ["ADMIN"] } },
  { path: 'consulta-pagos', component: ConsultaPagosComponent, canActivate: [AuthGuard] },
  { path: 'realiza-pago', component: RealizaPagoComponent, canActivate: [AuthGuard] },
  { path: 'pago-procesado', component: PagoProcesadoComponent },
  { path: 'estado-cuenta', component: EstadoCuentaComponent, canActivate: [AuthGuard] },
  { path: 'administracion-usuarios', component: AdministracionUsuariosComponent, canActivate: [AuthGuard], data: { roles: ["ADMIN"] } },
  { path: 'administracion-prospectos', component: AdministracionProspectosComponent, canActivate: [AuthGuard], data: { roles: ["ADMIN", "CARGOS"] } },
  { path: 'conciliacion-bancaria', component: ConciliacionBancariaComponent, canActivate: [AuthGuard], data: { roles: ["ADMIN", "CONCILIA"] } },
  { path: 'reporte', component: ReporteComponent, canActivate: [AuthGuard], data: { roles: ["ADMIN", "REPORTE"] } },
  { path: 'ayuda', component: AyudaComponent },
  { path: 'registro', component: RegistroComponent },
  { path: 'registro-espera/:correo', component: RegistroEsperaComponent },
  { path: 'recupera-cuenta', component: RecuperaCuentaComponent },
  { path: 'recupera-cuenta-espera/:correo', component: RecuperaCuentaEsperaComponent },
  { path: 'cambia-contrasena', component: CambiaContrasenaComponent },
  { path: 'cambia-contrasena-validar', component: CambiaContrasenaValidarComponent },
  { path: 'login', component: LoginComponent },
  { path: 'reporte', component: ReporteComponent },
  { path: 'activar', component: ActivarComponent },
  { path: 'logout', component: SignOutComponent }];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
