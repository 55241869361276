import { Component, OnInit, ViewChild } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DetalleProspectoComponent } from '../_components/detalle-prospecto/detalle-prospecto.component';
import { FormGroup, FormControl } from '@angular/forms';
import { Cargo } from '@app/_models/cargo';
import { Ciclo } from '@app/_models/ciclo';
import { CargoService } from '@app/_services/cargo.service';
import { first } from 'rxjs/operators';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { AuthenticationService } from '@app/_services/authentication.service';



@Component({
  selector: 'app-administracion-prospectos',
  templateUrl: './administracion-prospectos.component.html',
  styleUrls: ['./administracion-prospectos.component.scss']
})
export class AdministracionProspectosComponent implements OnInit {

  adminUserForm: FormGroup;
  searchControl = new FormControl();
  displayedColumns: string[] = ['correo', 'campus', 'monto','opciones'];
  

  valueArea: string;
  viewValueArea: string;
  dataSource = new MatTableDataSource<Cargo>();

  loading: boolean = false;
  error: boolean = false;
  errorMessage: string = "";
  disableEliminar = false;
  
  pageEvent: PageEvent;
  datasource: null;
  pageIndex:number = 0;
  pageSize:number = 5;
  length:number = 10

  filtro: string = "";
  result: string = '';

  idUsuario: number;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  
  constructor(public dialog: MatDialog, private cargoService:CargoService, private authenticationService: AuthenticationService,) {
    this.adminUserForm = new FormGroup({
      searchControl: new FormControl()
    });
   }

   getDataProspectos() {
    this.getCargos(this.pageSize, this.pageIndex + 1);
    this.getCount();
  }

  ngOnInit() {
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;
    this.dataSource.paginator = this.paginator;
    this.getDataProspectos();
  }


  public getServerData(event?:PageEvent){
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;      

    this.getDataProspectos();
    return event;
  }

  getCargos(nRegistros:number, nPagina:number) {
    this.loading = true;
    this.cargoService.consultaCargos(nRegistros, nPagina, this.filtro, this.idUsuario)
    .pipe(first())
    .subscribe(
      data => {
        this.dataSource = new MatTableDataSource<Cargo>(data);
        this.loading = false;
      },
      error => {
        this.error = true;
        this.errorMessage = "Error al obtener la lista de usuarios-empleados";
        this.loading = false;
      });
  }

  getCount():number{

    this.cargoService.getCount(this.filtro, this.idUsuario)
    .pipe(first())
    .subscribe(
      dataCount => {
        this.length = dataCount;
        return dataCount;
      },
      error => {
        this.error = true;
        this.errorMessage = "Error al obtener el total de registros";
      });

    return 0;
  }

  agregarProspecto(): void {

    const dialogRef = this.dialog.open(DetalleProspectoComponent, {
      width: '900px',
      data: {Operation: 'Crear', Cargo: {}}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getDataProspectos();
    });


  }


  editarProspecto(cargoElement:any): void {


    const dialogRef = this.dialog.open(DetalleProspectoComponent, {
      width: '900px',
      data: {Operation: 'Editar', Cargo: cargoElement}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getDataProspectos();

    });


  }


  eliminarCargo(cargoElement:Cargo): void {


    const message = `¿Está seguro de que  quiere borrar el cargo?`;

    const dialogData = new ConfirmDialogModel("Confirmar", message);

    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      this.result = dialogResult;
      if(this.result){
        this.cargoService.eliminarCargo(cargoElement).then( res => {
          if(res.Exito){
            this.getDataProspectos();
            
          }else{
            //Mostrar Mensaje res.Mensaje
          }
        }).catch(err => {
          console.log("Error"+ err);
        });


      }


    });

  }

  filterText(){
    this.pageIndex = 0;
    this.getDataProspectos();
  }




}
